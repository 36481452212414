import React from 'react';
import { MainLayout } from '../layouts';
import { LegalContainer } from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
      <div>
        <h1 id="credits">Credits</h1>
        <p>
          <em>Effective Date: November 30, 2019</em>
        </p>

        <p>AirPage uses content from the following photographers:</p>
        <a
          style={{
            backgroundColor: 'black',
            color: 'white',
            textDecoration: 'none',
            padding: '4px 6px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '1.2',
            display: 'inline-block',
            borderRadius: '3px'
          }}
          href="https://unsplash.com/@pavement_special?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge"
          target="_blank"
          rel="noopener noreferrer"
          title="Download free do whatever you want high-resolution photos from Riccardo Annandale"
        >
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: '12px',
                width: 'auto',
                position: 'relative',
                verticalAlign: 'middle',
                top: '-2px',
                fill: 'white'
              }}
              viewBox="0 0 32 32"
            >
              <title>unsplash-logo</title>
              <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
            </svg>
          </span>
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            Riccardo Annandale
          </span>
        </a>
        <hr/>
        <a
          style={{
            backgroundColor: 'black',
            color: 'white',
            textDecoration: 'none',
            padding: '4px 6px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '1.2',
            display: 'inline-block',
            borderRadius: '3px'
          }}
          href="https://unsplash.com/@johnschno?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge"
          target="_blank"
          rel="noopener noreferrer"
          title="Download free do whatever you want high-resolution photos from John Schnobrich"
        >
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: '12px',
                width: 'auto',
                position: 'relative',
                verticalAlign: 'middle',
                top: '-2px',
                fill: 'white'
              }}
              viewBox="0 0 32 32"
            >
              <title>unsplash-logo</title>
              <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
            </svg>
          </span>
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            John Schnobrich
          </span>
        </a>
        <hr/>
        <a
          style={{
            backgroundColor: 'black',
            color: 'white',
            textDecoration: 'none',
            padding: '4px 6px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '1.2',
            display: 'inline-block',
            borderRadius: '3px'
          }}
          href="https://unsplash.com/@efekurnaz?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge"
          target="_blank"
          rel="noopener noreferrer"
          title="Download free do whatever you want high-resolution photos from Efe Kurnaz"
        >
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: '12px',
                width: 'auto',
                position: 'relative',
                verticalAlign: 'middle',
                top: '-2px',
                fill: 'white'
              }}
              viewBox="0 0 32 32"
            >
              <title>unsplash-logo</title>
              <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
            </svg>
          </span>
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            Efe Kurnaz
          </span>
        </a>
        <hr/>
        <a
          style={{
            backgroundColor: 'black',
            color: 'white',
            textDecoration: 'none',
            padding: '4px 6px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '1.2',
            display: 'inline-block',
            borderRadius: '3px'
          }}
          href="https://unsplash.com/@brucemars?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge"
          target="_blank"
          rel="noopener noreferrer"
          title="Download free do whatever you want high-resolution photos from bruce mars"
        >
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: '12px',
                width: 'auto',
                position: 'relative',
                verticalAlign: 'middle',
                top: '-2px',
                fill: 'white'
              }}
              viewBox="0 0 32 32"
            >
              <title>unsplash-logo</title>
              <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
            </svg>
          </span>
          <span style={{ display: 'inline-block', padding: '2px 3px' }}>
            bruce mars
          </span>
        </a>
      </div>
    </LegalContainer>
  </MainLayout>
);
